import React from "react";
import {navigate} from "gatsby"

export default class RequestDemoMiniForm extends React.Component {
    state = {
        email: ''
    }

    handleChange = (e) => {
        this.setState({email: e.target.value});
    }

    handleSubmit = (e) => {
        e.preventDefault();

        navigate(
            "/book-a-demo/",
            {
                state: {email: this.state.email},
            }
        )
    }

    render() {
        const {variation} = this.props;
        return (
            <form action="" onSubmit={this.handleSubmit}>
                <input name={'email'} type="email" required={true}
                       className={"email" + (variation === 'integration' ? ' in' : '')}
                       placeholder={variation === 'integration' ? 'Email' : "Enter your email"} value={this.state.email}
                       onChange={this.handleChange}/>
                <input type="submit" className={"submit" + (variation === 'integration' ? ' btn' : '')}
                       value="REQUEST A DEMO"/>
            </form>
        )
    }
}